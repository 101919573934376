import { useState } from "react";
import { Outlet } from "react-router-dom";
import Popup from "../components/popup/popup";
import Footer from "../shared/footer/Footer";
import NavBar from "../shared/navbar/NavBar";
import NavMenu from "../shared/navmenu/NavMenu";

function BasicLayout() {
  const [expanded, setCheck] = useState<boolean>(false);

  function toggleMenu() {
    var navMenu = document.getElementById("navMenu")!;
    var faIcon = document.getElementById("faIcon")!;

    if (expanded) {
      navMenu.style.width = "0";
      setCheck(prevCheck => !prevCheck);
    }
    else {
      faIcon.classList.add('change-to-x');
      navMenu.style.width = "300px";
      setTimeout(() => setCheck(prevCheck => !prevCheck), 200);
    }
  }

  return (
    <>
      <NavBar toggleMenu={toggleMenu} expanded={expanded} />
      <NavMenu toggleMenu={toggleMenu} expanded={expanded} />
      <div className="outlet">
        <Popup />
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default BasicLayout;
