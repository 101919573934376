import "./popup.scss";

function Popup() {
  let res = sessionStorage.getItem("seenWarning");

  if (!res)
    setTimeout(() => {
      document.querySelector("dialog")?.showModal();
      sessionStorage.setItem("seenWarning", "true");
    }, 1000);

  return (
    <dialog className="popup">
      <h3>This page is not an official Valencia site!</h3>
      <p>
        This is privately hosted by Neil Elkadi, president of Tech Club as of Fall 2024.
        This page may be used as a resource but does not reflect the college, student government, or the club.
      </p>
      <form method="dialog">
        <button className="dia-button" autoFocus>OK</button>
      </form>
    </dialog>
  )
}

export default Popup;
